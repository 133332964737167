import { ThemeProvider } from "@mui/material/styles";
import { RainbowKitProvider } from "@rainbow-me/rainbowkit";
import "@rainbow-me/rainbowkit/styles.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { WagmiProvider } from "wagmi";
import { Authenticate } from "./components/authentication/authenticate";
import { theme } from "./components/layout/theme";
import { liskConfig, walletTheme } from "./components/wallet/config";
import { WalletConnector } from "./components/wallet/wallet_connector";
import { Beta } from "./pages/beta/beta";
import { Draw } from "./pages/draw/draw";
import { Gallery } from "./pages/gallery/gallery";
import { Home } from "./pages/home/home";
import { Pending } from "./pages/pending/pending";
import { Timelapse } from "./pages/timelapse/timelapse";
import { Verify } from "./pages/verify/verify";
import { reportWebVitals } from "./reportWebVitals";
import "./index.css";

const queryClient: QueryClient = new QueryClient();
const root = createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
    <ThemeProvider theme={theme}>
        <WagmiProvider config={liskConfig}>
            <QueryClientProvider client={queryClient}>
                <RainbowKitProvider theme={walletTheme}>
                    <WalletConnector />
                </RainbowKitProvider>
            </QueryClientProvider>
        </WagmiProvider>
        <BrowserRouter>
            <Routes>
                <Route path="/" index element={<Home />} />
                <Route path="/verify" index element={<Verify />} />
                <Route path="/" element={<Authenticate />}>
                    <Route path="beta" element={<Beta />} />
                    <Route path="draw" element={<Draw />} />
                    <Route path="gallery" element={<Gallery />} />
                    <Route path="pending" element={<Pending />} />
                    <Route path="timelapse" element={<Timelapse />} />
                </Route>
            </Routes>
        </BrowserRouter>
    </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
