import assert from "assert";
import { Address } from "viem";
import { WalletHandler } from "../wallet/wallet_handler";
import { ApiClient } from "./api_client";
import { serialiseColours, serialiseCoords } from "./codec";
import { ContractInfo } from "./types";

export class LiskClient {
    private static instance: LiskClient|null = null;
    private contractInfo: ContractInfo|null = null;

    private constructor() {}

    public static getInstance(): LiskClient {
        if (LiskClient.instance === null) {
            LiskClient.instance = new LiskClient();
        }

        assert(LiskClient.instance !== null);
        return LiskClient.instance;
    }

    public async contractAddress(): Promise<Address> {
        const contractInfo = await this.loadContractInfo();
        return contractInfo.contractAddress as Address;
    }

    public async contractAbi(): Promise<any> {
        const contractInfo = await this.loadContractInfo();
        return contractInfo.contractAbi;
    }

    public async tokenAddress(): Promise<Address> {
        const contractInfo = await this.loadContractInfo();
        return contractInfo.tokenAddress as Address;
    }

    public async tokenAbi(): Promise<any> {
        const contractInfo = await this.loadContractInfo();
        return contractInfo.tokenAbi as Address;
    }

    public async draw(canvasId: number, coords: number[], colours: number[]): Promise<void> {
        const client = await WalletHandler.getInstance().getWalletClient();
        await client.writeContract({
            address: await this.contractAddress(),
            abi: await this.contractAbi(),
            functionName: "draw",
            args: [canvasId, "0x" + serialiseCoords(coords).toString("hex"), "0x" + serialiseColours(colours).toString("hex")],
        })
    }

    private async loadContractInfo(): Promise<ContractInfo> {
        if (this.contractInfo === null) {
            this.contractInfo = await ApiClient.getInstance().queryContractInfo();
        }

        return this.contractInfo;
    }
}
