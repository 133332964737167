import assert from "assert";
import { ApiClient } from "../clients/api_client";
import { Canvas } from "../clients/types";
import { WalletHandler } from "../wallet/wallet_handler";

export class CanvasCache {
    private static instance: CanvasCache|null = null;
    private activeCanvas: Canvas|null|undefined = undefined;
    private pendingCanvas: Canvas|null|undefined = undefined;

    private constructor() {}

    public static getInstance(): CanvasCache {
        if (CanvasCache.instance === null) {
            CanvasCache.instance = new CanvasCache();
        }

        assert(CanvasCache.instance !== null);
        return CanvasCache.instance;
    }

    public refresh(): CanvasCache {
        this.activeCanvas = undefined;
        this.pendingCanvas = undefined;
        return this;
    }

    public async getActiveCanvas(): Promise<Canvas|null> {
        if (this.activeCanvas !== undefined) {
            return this.activeCanvas;
        }

        if (!(await WalletHandler.getInstance().isAuthenticated())) {
            return null;
        }

        const active = await ApiClient.getInstance().queryActiveCanvases();

        let minCanvas: Canvas|null = null;

        for (const canvasId of active) {
            const canvasProps = await ApiClient.getInstance().queryCanvas(canvasId);

            if (!canvasProps) {
                continue;
            }

            // Searching for earliest ending active canvas
            if (minCanvas === null || canvasProps.blockRange.end < minCanvas.blockRange.end) {
                minCanvas = canvasProps;
            }
        }

        this.activeCanvas = minCanvas;
        return this.activeCanvas;
    }

    public async getPendingCanvas(): Promise<Canvas|null> {
        if (this.pendingCanvas !== undefined) {
            return this.pendingCanvas;
        }

        if (!(await WalletHandler.getInstance().isAuthenticated())) {
            return null;
        }

        const pending = await ApiClient.getInstance().queryPendingCanvases();

        let minCanvas: Canvas|null = null;

        for (const canvasId of pending) {
            const canvasProps = await ApiClient.getInstance().queryCanvas(canvasId);

            if (!canvasProps) {
                continue;
            }

            // Searching for earliest starting pending canvas
            if (minCanvas === null || canvasProps.blockRange.start < minCanvas.blockRange.start) {
                minCanvas = canvasProps;
            }
        }

        this.pendingCanvas = minCanvas;
        return this.pendingCanvas;
    }
}
